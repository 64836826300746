<template>
  <div class="inviteCode">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">绑定邀请码</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="bindTitle">绑定邀请码</div>
      <div class="tip">邀请码只能绑定一次 且不能修改</div>
      <van-field
        @blur="changBlur"
        class="inviteCodeInput"
        v-model="inviteCode"
        :border="false"
        label="邀请码"
        placeholder="请输入邀请码 (字母大写)"
      />
      <!-- <div class="inviteTip">你和邀请人各获得1天会员</div> -->

      <div class="bindBtn" @click="bindCode">确定</div>
    </div>
  </div>
</template>

<script>
import { Field, Loading, Toast } from "vant";
import { bindInviteCode } from "@/api/user";
export default {
  components: {
    [Field.name]: Field,
    // [Loading.name]: Loading,
  },
  data() {
    return {
      inviteCode: "",
    };
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 绑定邀请码
    async bindCode() {
      if (!this.inviteCode) {
        Toast("请填写邀请码");
        return;
      }

      let req = {
        promotionCode: this.inviteCode,
      };
      let res = await this.$Api(bindInviteCode, req);
      if (res.code === 200) {
        this.$router.go(-1);
        Toast("邀请码绑定成功");
      } else {
        Toast(res.tip || "绑定失败请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inviteCode {
  height: 100%;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 28px;
    box-sizing: border-box;

    .bindTitle {
      margin-top: 32px;
      font-family: "Dream Han Sans TC";
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      color: #333;
      line-height: 22px;
    }

    .tip {
      font-size: 16px;
      margin-top: 12px;
      font-weight: 500;
      line-height: 22px;
      color: #666;
      font-family: "PingFang SC";
    }

    .inviteCodeInput {
      width: 323px;
      height: 36px;
      padding: 8px 10px;
      box-sizing: border-box;
      //   padding: 0 0 12px;
      margin-top: 32px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      background: #f5f5f5;
      box-shadow: 2px 2px 4px 0px rgba(174, 174, 192, 0.2) inset,
        -5px -5px 4px 0px rgba(255, 255, 255, 0.3) inset;
      /deep/ .van-field__label {
        width: 42px;
        margin-right: 12px;
        font-size: 14px;
        font-weight: 500;
        font-family: "PingFang SC";
        color: #333;
        display: flex;
        align-items: center;
      }
      /deep/ .van-field__control {
        font-size: 12px;
        line-height: 18px;
        color: #000;
      }
      ::placeholder {
        color: #999999;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .inviteTip {
    color: #999999;
    font-size: 14px;
    margin-top: 10px;
  }
  .bindBtn {
    width: 333px;
    height: 56px;
    border-radius: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 18px;
    color: #b09fd9;
    font-family: "Dream Han Sans TC";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    border-radius: 30px;
    background: #fff;
    box-shadow: -5px -5px 20px 0px #fff,
      5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .isDisable {
    background: #abaaba;
  }
}
</style>
